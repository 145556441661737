import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';

export default function Logo({ disabledLink = false, sx, ...props }) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width={38.725} height={38.729} {...props}>
        <path
          d="M21.389.108A19.346 19.346 0 1 1 19.358 0a18.937 18.937 0 0 1 2.031.108Zm1 37.267A18.265 18.265 0 1 0 10.815 3.224C19.583 6.2 24.3 10.719 24.626 15.349a8.259 8.259 0 0 1-2.916 6.613l12.4-.865c.352-.024.461.385.036.5L4.57 30.085a18.24 18.24 0 0 0 17.816 7.29Zm-8.137-17.588c1.622-6.092-.229-12.481-4.652-15.866a18.278 18.278 0 0 0-5.676 25.2c4.712-1.311 8.853-3.794 10.328-9.334Z"
          style={{
            fill: '#fff',
          }}
        />
        <path
          d="M39.008 17.983A18.265 18.265 0 0 1 6.207 31.73l29.573-8.48c.425-.12.316-.529-.036-.5l-12.4.865A8.259 8.259 0 0 0 26.263 17c-.328-4.634-5.043-9.157-13.811-12.129a18.256 18.256 0 0 1 26.556 13.112ZM2.987 24.036a18.125 18.125 0 0 0 2.572 6.733c4.714-1.314 8.856-3.8 10.33-9.333 1.622-6.092-.228-12.481-4.654-15.866a18.261 18.261 0 0 0-8.248 18.466Z"
          transform="translate(-1.637 -1.645)"
          style={{
            fill: '#c90019',
          }}
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};
