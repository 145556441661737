import request from './request';
import * as paths from './paths';

class airport {
  /**
   *
   * airport list
   *
   * @static
   * @memberof airport
   */
  static list = async () => {
    return await request.get(paths.airport);
  };

  /**
   *
   * airport get
   *
   * @static
   * @memberof airport
   */
  static get = async (id) => {
    return await request.get(`${paths.airport}/${id}`);
  };

  /**
   *
   * add new airport
   *
   * @static
   * @memberof airport
   */
  static save = async (params) => {
    return await request.post(paths.airport, params);
  };
}

export { airport };
