import request from './request';
import * as paths from './paths';

class permission {
  /**
   *
   * menu list
   *
   * @static
   * @memberof permission
   */
  static getAllMenu = async (params) => {
    return await request.get(paths.menuItems, params);
  };
  /**
   *
   * selected menu list
   *
   * @static
   * @memberof permission
   */
  static getSelectedMenus = async (roleCode, organizationId) => {
    return await request.get(`${paths.menuPermissions}/${roleCode}/${organizationId}`);
  };
  /**
   *
   * save menu permissions
   *
   * @static
   * @memberof permission
   */
  static saveMenuPermissions = async (roleCode, organizationId, params) => {
    return await request.post(`${paths.menuPermissions}/${roleCode}/${organizationId}`, params);
  };
  /**
   *
   * selected field list
   *
   * @static
   * @memberof permission
   */
  static getSelectedFields = async (roleCode, organizationId, params) => {
    return await request.get(`${paths.fieldPermissions}${roleCode}/${organizationId}`, params);
  };
  /**
   *
   * save field permissions
   *
   * @static
   * @memberof permission
   */
  static saveFieldPermissions = async (params) => {
    return await request.post(`${paths.fieldPermissions}update`, params);
  };
  /**
   *
   * get permission by entity code
   *
   * @static
   * @memberof permission
   */
  static getPermByEntityCode = async (id) => {
    return await request.get(paths.permByEntityCode + id);
  };
  /**
   *
   * get permission by menu code
   *
   * @static
   * @memberof permission
   */
  static getPermByMenuCode = async (id) => {
    return await request.get(paths.permByMenuCode + id);
  };
}

export { permission };
