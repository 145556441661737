//paths

//role
export const role = 'roles/';

//hub
export const hub = 'fields/hubs/';

//content
export const content = 'fields/hasContent/';

//airport
export const airport = 'airports';

//menu-permission
export const menuItems = 'menuItems/';
export const menuPermissions = 'menuItems/permissions';

//field-permission
export const fieldPermissions = 'fields/permissions/';
export const permByEntityCode = 'fields/permissionsByEntityCode/';
export const permByMenuCode = 'fields/permissionsByMenuItemCode/';

//organizations
export const organizations = 'organizations/';

//ownership
export const ownerships = 'fields/ownership';
