import { permission } from './permission';
import { role } from './role';
import { organization } from './organization';
import { ownership } from './ownership';
import { hub } from './hub';
import { content } from './content';
import { airport } from './airport';



/**
 * All api calls
 *
 * @class api
 */
class api {
  /**
   *
   * Role
   * @static
   * @memberof api
   */
  static role = role;
  /**
   *
   * Hub
   * @static
   * @memberof api
   */
  static hub = hub;
  /**
   *
   * Content
   * @static
   * @memberof api
   */
  static content = content;
  /**
   *
   * Permission
   * @static
   * @memberof api
   */
  static permission = permission;
  /**
   *
   * Organization
   * @static
   * @memberof api
   */
  static organization = organization;
  /**
   *
   * Ownership
   * @static
   * @memberof api
   */
  static ownership = ownership;
  /**
   *
   * Airport
   * @static
   * @memberof api
   */
  static airport = airport;
}

export { api };
