import request from './request';
import * as paths from './paths';

class role {
  /**
   *
   * role list
   *
   * @static
   * @memberof campaign
   */
  static list = async (params) => {
    return await request.get(paths.role, params);
  };
  /**
   *
   * save role
   *
   * @static
   * @memberof campaign
   */
  static save = async (params) => {
    return await request.post(paths.role, params);
  };
  /**
   *
   * update role
   *
   * @static
   * @memberof campaign
   */
  static update = async (params, code) => {
    return await request.put(paths.role + code, params);
  };
  /**
   *
   * delete role
   *
   * @static
   * @memberof campaign
   */
  static delete = async (params) => {
    return await request.delete(paths.role + params);
  };
}

export { role };
