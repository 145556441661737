import * as actionTypes from './role.type';

const initialState = {
  roleList: [],
  selectedRole: null,
  submitted: false,
};

const reducer = (state = initialState, { type, payload, roleList, selectedRole, submitted, ...params }) => {
  switch (type) {
    case actionTypes.SAVE_ROLE:
      return { ...state, roleList: payload };
    case actionTypes.SAVE_SELECTED_ROLE:
      return { ...state, selectedRole: payload };
    case actionTypes.SAVE_SUBMITTED:
      return { ...state, submitted: payload };
    case actionTypes.CLEAR_ROLE:
      return { ...state, roleList: [], selectedRole: null, submitted: false };
    default:
      return state;
  }
};

export default reducer;
