import request from './request';
import * as paths from './paths';

class organization {
  /**
   *
   * organization list
   *
   * @static
   * @memberof organization
   */
  static list = async (params) => {
    return await request.get(paths.organizations, params);
  };
}

export { organization };
