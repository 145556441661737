import request from './request';
import * as paths from './paths';

class hub {
  /**
   *
   * field list
   *
   * @static
   * @memberof hub
   */
  static getFieldList = async (params) => {
    return await request.get(paths.hub, params);
  };
  /**
   *
   * save hub definitions
   *
   * @static
   * @memberof hub
   */
  static saveHubDefinition = async (params) => {
    return await request.post(paths.hub, params);
  };
}

export { hub };
