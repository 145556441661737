import Router from './routes';
import ThemeProvider from './utils/theme';
import Settings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './utils/theme/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { Toaster } from 'react-hot-toast';
import { LocaleProvider } from './hooks/useLocale';
import ThemeLocalization from './utils/theme/ThemeLocalization';

export default function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <LocaleProvider>
          <ThemeLocalization>
            <MotionLazyContainer>
              <Toaster position="top-right" />
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </MotionLazyContainer>
          </ThemeLocalization>
        </LocaleProvider>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
