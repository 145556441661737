import request from './request';
import * as paths from './paths';

class content {
  /**
   *
   * content list
   *
   * @static
   * @memberof content
   */
  static getHasContentList = async (params) => {
    return await request.get(paths.content, params);
  };
  /**
   *
   * save hub definitions
   *
   * @static
   * @memberof hub
   */
  static saveContentDefinition = async (params) => {
    return await request.post(paths.content, params);
  };
}

export { content };
