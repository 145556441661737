import React, { createContext, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { enUS, trTR } from '@mui/material/locale';

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
  },
  {
    label: 'Türkçe',
    value: 'tr',
    systemValue: trTR,
    icon: 'https://flagicons.lipis.dev/flags/4x3/tr.svg',
  },
];

const LocaleContext = createContext({});

export const LocaleProvider = ({ children }) => {
  const { i18n, t } = useTranslation();
  const cookieLanguage = localStorage.getItem('i18nextLng') || 'tr';
  const currentLanguage = LANGS.find((_lang) => _lang.value === cookieLanguage);
  const [locale, setLocale] = useState(cookieLanguage);

  i18n.locale = locale;

  function changeLocale(localeParam) {
    setLocale(localeParam);
    i18n.changeLanguage(localeParam);
  }

  return <LocaleContext.Provider value={{ t, locale, currentLanguage, changeLocale, allLanguages: LANGS }}>{children}</LocaleContext.Provider>;
};

export default function useLocale() {
  const context = useContext(LocaleContext);

  return context;
}
