import * as actionTypes from './organization.type';

const initialState = {
  organizationList: [],
  selectedOrganization: null,
  submitted: false,
};

const reducer = (state = initialState, { type, payload, organizationList, selectedOrganization, submitted, ...params }) => {
  switch (type) {
    case actionTypes.SAVE_ORGANIZATION:
      return { ...state, organizationList: payload };
    case actionTypes.SAVE_SELECTED_ORGANIZATION:
      return { ...state, selectedOrganization: payload };
    case actionTypes.SAVE_SUBMITTED:
      return { ...state, submitted: payload };
    case actionTypes.CLEAR_ORGANIZATION:
      return { ...state, organizationList: [], selectedOrganization: null, submitted: false };
    default:
      return state;
  }
};

export default reducer;
