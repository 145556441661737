import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../components/layouts/DashboardLayout';
import LogoOnlyLayout from '../components/layouts/LogoOnlyLayout';
import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';
import Loading from '../components/Loading';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={'/dashboard/app'} replace />, index: true },
        { path: 'app', element: <Dashboard /> },
        { path: 'definitions', children: [
            { path: 'role-definition', element: <RoleDefinition /> },
            { path: 'hub-definition', element: <HubDefinition /> },
            { path: 'content-definition', element: <ContentDefinition /> }
          ] },
        {
          path: 'permission',
          children: [
            { path: 'field-menu-permission', element: <DefinePermission /> },
            { path: 'field-ownership', element: <FieldOwnership /> },
          ],
        },

        {
          path: 'airport',
          children: [
            { path: 'airport-list', element: <AirportList /> },
            { path: 'airport-list/detail/:id', element: <AirportDetail /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },

            { path: 'profile', element: <Profile /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
      children: [],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/Auth/Login')));

const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const DefinePermission = Loadable(lazy(() => import('../pages/dashboard/DefinePermission')));
const RoleDefinition = Loadable(lazy(() => import('../pages/dashboard/RoleDefinition')));
const HubDefinition = Loadable(lazy(() => import('../pages/dashboard/HubDefinition')));
const ContentDefinition = Loadable(lazy(() => import('../pages/dashboard/ContentDefinition')));
const AirportList = Loadable(lazy(() => import('../pages/dashboard/Airport/AirportList')));
const AirportDetail = Loadable(lazy(() => import('../pages/dashboard/Airport/AirportDetail')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile')));
const FieldOwnership = Loadable(lazy(() => import('../pages/dashboard/FieldOwnership')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
