import request from './request';
import * as paths from './paths';

class ownership {
  /**
   *
   * ownership list
   *
   * @static
   * @memberof ownership
   */
  static list = async (params) => {
    return await request.get(paths.ownerships, params);
  };

  /**
   *
   * put field ownership
   *
   * @static
   * @memberof ownership
   */
  static updateFieldOwnership = async (params) => {
    return await request.post(paths.ownerships, params);
  };

  /**
   *
   * delete field ownership
   *
   * @static
   * @memberof ownership
   */
  static deleteFieldOwnership = async (id) => {
    return await request.delete(`${paths.ownerships}/${id}`, id);
  };
}

export { ownership };
