import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../../../components/Iconify';
import Button from '../../../components/Button';

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="username" label="Kullanıcı Adı" onChange={onChange} />

        <TextField
          name="password"
          label="Şifre"
          onChange={onChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={'/auth/reset-password'}>
          Şifremi Unuttum
        </Link>
      </Stack>

      <Button
        onClick={() => {
          localStorage.setItem('token', 'saasddas');
          navigate('/dashboard');
        }}
        size="large"
        variant="contained"
        sx={{ mb: 3 }}
      >
        Giriş Yap
      </Button>
    </>
  );
}
