import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { HEADER, NAVBAR } from '../../../config';
import DashboardHeader from '../../Header';
import Navbar from '../../NavBar';

const MainStyle = styled(
  'main',
  {}
)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 } }}>
      <DashboardHeader onOpenSidebar={() => setOpen(true)} />

      <Navbar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
