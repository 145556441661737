import { combineReducers } from 'redux';

import role from './role/role.reducer';
import organization from './organization/organization.reducer';
import airport from './airport/airport.reducer';

const rootReducer = combineReducers({
  role,
  organization,
  airport,
});

export default rootReducer;
