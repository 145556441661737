import SvgIconStyle from './SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: getIcon('m_home'),
  user: getIcon('m_user'),
  permission: getIcon('m_key'),
  plus: getIcon('m_plus'),
  plane: getIcon('m_plane'),
};

const navConfig = [
  {
    subheader: 'Anasayfa',
    menuCode: '1',
    single: true,
    items: [
      {
        title: 'Anasayfa',
        path: '/dashboard/app',
        icon: ICONS.home,
        menuCode: '1',
      },
    ],
  },

  {
    subheader: 'Tanımlamalar',
    menuCode: '3',
    items: [
      {
        title: 'Tanımlamalar',
        icon: ICONS.plus,
        menuCode: '3',
        children: [
          {
            title: 'Rol Tanımlama',
            path: '/dashboard/definitions/role-definition',
            menuCode: '5',
          },
          {
            title: 'Hub Tanımlama',
            path: '/dashboard/definitions/hub-definition',
            menuCode: '9',
          },
          {
            title: 'Content Tanımlama',
            path: '/dashboard/definitions/content-definition',
            menuCode: '10',
          },
        ],
      },
    ],
  },

  {
    subheader: 'Yetkilendirme',
    menuCode: '2',
    items: [
      {
        title: 'Yetkilendirme',
        icon: ICONS.permission,
        menuCode: '2',
        children: [
          {
            title: 'Alan-Menü Yetkilendirme',
            path: '/dashboard/permission/field-menu-permission',
            menuCode: '4',
          },
          {
            title: 'Alan Sahipliği',
            path: '/dashboard/permission/field-ownership',
            menuCode: '8',
          },
        ],
      },
    ],
  },

  {
    subheader: 'Havalimanı',
    menuCode: '6',
    items: [
      {
        title: 'Havalimanı',
        icon: ICONS.plane,
        children: [
          {
            title: 'Havalimanı Listesi',
            path: '/dashboard/airport/airport-list',
            menuCode: '7',
          },
        ],
      },
    ],
  },
];

export default navConfig;
