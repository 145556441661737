import * as actionTypes from './general.type';

const initialState = {
  fields: {
    name: '',
    iataCode: '',
    city: '',
    state: '',
    country: '',
    generalRegion: null,
    detailedRegion: null,
    latitude: '',
    longitude: '',
    firstFlightDate: null,
    exclusiveDriveDepartureFlight: false,
    transportationInformation: '',
    hasFoodCourt: false,
    airportRestaurants: '',
    hasDutyFree: false,
    listOfDutyFreeShops: '', // ???
    hasWifi: false,
    freeWifiDurationMinute: null,
    wifiPrices: '',
    hasTaxFreeOpportunity: false,
    hasWheelChairOpportunity: false,
    countWheelChair: '',
    salesOffice: false,
    hasSmokingArea: false,
    hasMasjid: false,
    hasPrayerHall: false,
    hasAccessibleWC: false,
    countAirClinicFrontLine: '',
    countGate: '',
    countBridge: '',
    countParkPosition: '',
    hasHubStation: false,
    bondedDutyFree: null,
    countBagCarousel: '',
    ofOxygenTubes: '', // ???
    hasMeetAndAssistanceService: false,
    countTerminal: '',
    handlingFirm: '',
    exclusiveDriveArrivalFlight: false,
    hasMiniPort: false,
    isSilentAirport: false,
    hasFastTrack: false,
    hasBuggyCar: false,
  },
};

const reducer = (state = initialState, { type, payload, fields, ...params }) => {
  switch (type) {
    case actionTypes.SET_INITIAL_FIELD:
      return { ...state, fields: { ...state.fields, ...payload } };
    case actionTypes.SAVE_FIELD:
      return { ...state, fields: { ...state.fields, [payload.name]: payload.value } };
    case actionTypes.CLEAR_FIELD:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
