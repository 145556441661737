const globalStyle = {
  textTruncate: {
    display: 'block',
    width: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default globalStyle;
