import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Link } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import { NAVBAR } from '../../config';
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import NavSection from './sections';
import navConfig from './sections/NavConfig';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
// import NavbarAccount from './sections/NavbarAccount';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function Navbar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [menu, setMenu] = useState([]);

  const getAuthorizedMenuCodes = async () => {
    const { data = {}, error } = await api.permission.getSelectedMenus('kod9', 10000542);

    if (error) return toast.error(error);

    let result = [];

    const singleMenu = navConfig?.filter((item) => item?.single && !data?.includes(item?.items?.[0]?.menuCode)).map((item) => item?.menuCode);

    result.push(
      ...navConfig
        ?.map((item) => {
          return {
            ...item,
            items: item?.items?.map((i) => {
              return {
                ...i,
                ...(i?.children?.length !== 0 && { children: i?.children?.filter((menu) => data?.includes(menu?.menuCode)) }),
              };
            }),
          };
        })
        .filter((item) => item?.items?.[0]?.children?.length !== 0)
        .filter((item) => !singleMenu.includes(item?.items?.[0]?.menuCode)) // TODO - Kerim - map?
    );

    setMenu(result);
  };

  useEffect(() => {
    getAuthorizedMenuCodes();
  }, []);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar sx={{ height: 1, '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' } }}>
      <Stack spacing={3} sx={{ pt: 3, pb: 2, px: 2.5, flexShrink: 0 }}>
        <Stack direction="row" alignItems="center">
          <Logo />
          <Link href="/" color="inherit" underline="none">
            <Typography sx={{ ml: 4 }} variant="h5">
              THY - MVT
            </Typography>
          </Link>
        </Stack>

        {/* <NavbarAccount /> */}
      </Stack>

      <NavSection navConfig={menu} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle sx={{ width: { lg: NAVBAR.DASHBOARD_WIDTH } }}>
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

Navbar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};
