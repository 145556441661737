import createAvatar from '../../utils/createAvatar';
import { Avatar as MyAvatar } from './Avatar';

export default function Avatar({ ...other }) {
  return (
    <MyAvatar
      // src={}
      alt="Kerim Keskin"
      color={createAvatar('Kerim').color}
      {...other}
    >
      {createAvatar('Kerim').name}
    </MyAvatar>
  );
}
