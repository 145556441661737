import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import componentsOverride from './overrides';

export default function ThemeColorPresets({ children }) {
  const defaultTheme = useTheme();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: {
          name: 'default',
          lighter: '#FFE3D5',
          light: '#FFC1AC',
          main: '#FF3030',
          dark: '#B71833',
          darker: '#7A0930',
          contrastText: '#fff',
        },
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha('#FF3030', 0.24)}`,
      },
    }),
    [defaultTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

ThemeColorPresets.propTypes = {
  children: PropTypes.node,
};
